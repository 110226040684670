@font-face {
  font-family: Ethnocentric;
  src: url("./fonts/ethnocentric.otf");
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body,
html {
  background-color: #050505;
  color: white;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  font-family: Montserrat;
}

#root {
  background-color: #050505;
  color: white;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
}

div,
span {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

dialog {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  margin: 0;
  background-color: transparent;
  color: white;
}

dialog.clear {
  width: unset;
  max-width: unset;
  background-color: unset;
  border: unset;
  border-radius: unset;
  color: unset;
  padding: unset;
  transition: all 0.3s;
}

dialog::backdrop {
  background: #0f1820f8;
}

dialog.clear::backdrop {
  background: unset;
  backdrop-filter: unset;
}

dialog:focus-visible {
  outline: none;
}

input {
  background-color: transparent;
  box-shadow: none;
  transition: all 0.3s;
  color: white;
  padding: 9px 10px 9px 14px;
  font: normal normal bold 20px/24px Montserrat;
}

input:hover {
}

input:focus {
  outline: none;
}

input:active {
}

input:focus-visible {
}

.diagonal-container {
  border: 1px solid #3f5a6d;
  border-radius: 8px;
  background: linear-gradient(-20deg, #1c3241 0%, #0b151c 50%, #1c3241 100%);
}

table {
  border-collapse: collapse;
}

tr,
th {
  text-align: center;
}

/* Odd rows (1st, 3rd, 5th, etc.) */
tr:nth-child(odd) {
  background-color: #0c1c27;
}

/* Even rows (2nd, 4th, 6th, etc.) */
tr:nth-child(even) {
  background-color: #071219;
}

th,
tr {
  border-bottom: none;
}
