@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  transform-origin: center;
  animation: spin 0.75s infinite linear;
}
